<script setup lang="ts">
import type { NavigationItem } from 'types';

const props = defineProps<{
  navItem: NavigationItem;
  index?: number;
  open: boolean;
}>();
</script>

<template>
  <div>
    <div class="flex">
      <BaseLink
        :to="navItem.linkUrl"
        :title="navItem.menuTitle"
        class="flex w-full flex-1 items-center py-2.5"
      >
        {{ navItem.menuTitle }}
      </BaseLink>
      <BaseIcon
        :class="{ 'rotate-180': open }"
        class="text-xl ml-auto flex h-12 w-12 -rotate-0 items-center justify-center transition-transform duration-200"
        name="chevron-down"
      />
    </div>
    <div v-if="open">
      <ul
        :key="props.index"
        :class="{ active: open }"
        class="sub-links pb-4"
      >
        <li
          v-for="(item, i) in navItem.children"
          :key="i"
          class="px-2"
        >
          <BaseLink
            :to="item.linkUrl"
            :title="item.menuTitle"
            class="flex h-full items-center px-6 py-2"
          >
            {{ item.menuTitle }}
          </BaseLink>
        </li>
      </ul>
    </div>
  </div>
</template>
