<script setup lang="ts">
import type { MetadataLanguage } from 'types';

const root = useRoot();
const navigation = useNavigation();
const languageList = shallowRef<HTMLElement | null>(null);
const languageListVisible = shallowRef<boolean>(false);
const metaDataReady = shallowRef<boolean>(false);

const setLanguageListVisibility = (value: boolean) => {
  languageListVisible.value = value;
};

const setMetaDataReady = (value: boolean) => {
  metaDataReady.value = value;
};

onClickOutside(languageList, () => setLanguageListVisibility(false));

function getAvailableLanguages(): MetadataLanguage[] | undefined {
  if (navigation.activeContentMetaData?.languages !== undefined) {
    const availableLanguages: MetadataLanguage[] = navigation.activeContentMetaData.languages.map(
      (metaDataLanguage: MetadataLanguage) => metaDataLanguage
    );

    const allLanguages: MetadataLanguage[] = [...availableLanguages];

    root.settings.metadata.languages.forEach((language: MetadataLanguage) => {
      if (!availableLanguages.some((availableLanguage) => availableLanguage.language === language.language)) {
        const newLanguage: MetadataLanguage = {
          ...language,
          url: frontendBaseWithLocale(language.language)
        };
        allLanguages.push(newLanguage);
      }
    });

    return allLanguages;
  }

  return undefined;
}

onMounted(() => {
  setMetaDataReady(true);
});
</script>

<template>
  <div
    v-if="getAvailableLanguages() !== undefined && metaDataReady"
    class="relative z-[51]"
  >
    <button
      type="button"
      :title="$t('General.SwitchLanguage')"
      aria-controls="language-list"
      :class="languageListVisible ? 'border-primary text-primary' : 'border-transparent'"
      class="languageSelector lg:text-xl box-border flex h-full w-full flex-1 cursor-pointer items-center justify-center border-b px-5 py-2 font-medium uppercase lg:p-0"
      @click="setLanguageListVisibility(!languageListVisible)"
    >
      {{ $t(`Languages.DisplayNames.${navigation.activeCulture}`) }}
      <BaseIcon
        :class="{ 'rotate-180': languageListVisible }"
        class="relative top-px ml-1 flex h-5 w-5 items-center justify-center text-icon transition-transform duration-200"
        name="chevron-down"
      />
      <Transition name="fade">
        <div v-show="languageListVisible">
          <ul
            id="language-list"
            ref="languageList"
            :aria-expanded="languageListVisible"
            class="languageSelector absolute bottom-full left-1/2 flex h-min w-full -translate-x-1/2 flex-col border border-gray-40 bg-white px-2 py-4 drop-shadow-sm lg:bottom-0 lg:top-[110%] lg:w-min"
          >
            <li
              v-for="language in getAvailableLanguages()"
              :key="language.url"
              class="relative flex"
            >
              <BaseLink
                class="box-border flex-1 px-8 py-3 text-center text-main transition-colors duration-300 hover:text-primary"
                :title="$t(`Languages.DisplayNames.${language.language}`)"
                :to="language.url"
                :has-underline="true"
              >
                {{ $t(`Languages.DisplayNames.${language.language}`) }}
              </BaseLink>
            </li>
          </ul>
        </div>
      </Transition>
    </button>
  </div>
</template>
