<script setup lang="ts">
const root = useRoot();
const logoLink = ref('/');
const navigation = useNavigation();

type Props = {
  logo: string;
  loading: 'lazy' | 'eager';
};

defineProps<Props>();

const { isMultilingualSite } = useMultiLingual();

const logoAlt = computed(() => {
  return root.settings.content.siteLogo.name;
});

function handleClick() {
  if (!navigation.isFrontPage) {
    return;
  }

  const DOM = useDOM();

  DOM?.window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}

const frontpageUrl = computed(() => {
  if (isMultilingualSite()) {
    return logoLink.value + root.settings.metadata.culture;
  }

  return logoLink.value;
});
</script>

<template>
  <BaseLink
    :to="frontpageUrl"
    @click="handleClick"
  >
    <BaseImage
      class="min-h-[40px] object-contain transition-opacity duration-400 ease-smooth hover:opacity-75 lg:min-h-[48px]"
      :height="48"
      :width="174"
      :src="useCdnBase(logo, true)"
      :alt="logoAlt"
      :loading="loading"
      :preload="loading === 'eager' ? true : false"
    />
  </BaseLink>
</template>
