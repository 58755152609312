<script setup lang="ts">
const navigation = useNavigation();
</script>

<template>
  <button
    role="button"
    class="hamburger"
    :class="{ open: navigation.menuVisible }"
    :aria-label="$t('Header.OpenMenu')"
  >
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>

<style lang="postcss" scoped>
.hamburger {
  position: relative;
  width: 30px;
  height: 23px;
  cursor: pointer;

  span {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: 3px;
    background: var(--color-text);
    transition: 250ms ease-in-out;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 9px;
  }

  span:nth-child(4) {
    top: 18px;
  }

  &.open {
    span:nth-child(1) {
      top: 5px;
      left: 50%;
      width: 0%;
    }

    span:nth-child(2) {
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      top: 5px;
      left: 50%;
      width: 0%;
    }
  }
}
</style>
