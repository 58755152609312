<script setup lang="ts">
import type { Link } from 'types';
import type { SearchSuggestion } from 'types/search';

const props = defineProps<{
  searchVisible?: boolean;
  searchPage: Link;
}>();

const inputValue = shallowRef<string>('');
const searchBar = shallowRef<HTMLInputElement | null>(null);
const suggestions = ref<SearchSuggestion[]>([]);
const input = ref<HTMLElement>();

const { query } = useRoute();
const search = useSearchApi();
const navigation = useNavigation();

async function submitSearch() {
  navigation.searchVisible = false;

  await navigateTo({
    path: handleRelativeUrl(props.searchPage.url),
    query: { term: inputValue.value }
  });

  inputValue.value = '';
}

onMounted(() => {
  if (query.term) {
    inputValue.value = String(query.term);
  }

  nextTick(() => {
    input.value?.focus();
  });
});

async function getSuggestions() {
  const result = await search.getSearchSuggestions(inputValue.value);
  suggestions.value = result.searchSuggestions;
}

async function pickSuggestion(e: MouseEvent) {
  const target = e.target as HTMLElement;
  inputValue.value = target.innerText;

  await submitSearch();
}

const activeElement = useActiveElement();
const activeSuggestion = ref<number>(-1);
const dom = useDOM();
const { focused } = useFocusWithin(input);
const { down, up, enter, space } = useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (focused && (down.value || up.value)) {
      e.preventDefault();
    }
  }
});

watchEffect(async () => {
  if (inputValue.value.length < 2) {
    suggestions.value = [];
  }

  if (focused) {
    if (activeSuggestion.value < suggestions.value.length - 1 && down.value) {
      activeSuggestion.value += 1;
    }

    if (activeSuggestion.value > -1 && up.value) {
      activeSuggestion.value -= 1;
    }

    if (activeSuggestion.value === -1) {
      input.value?.focus();
    }
  }
  if (enter.value || space.value) {
    if (activeElement.value?.innerText) {
      inputValue.value = activeElement.value?.innerText;
      await submitSearch();
    }
  }
  dom?.document.getElementById(`suggestion-${activeSuggestion.value}`)?.focus();
});
</script>

<template>
  <div
    ref="searchBar"
    class="z-40 w-full bg-white py-4 md:py-8"
  >
    <div class="container">
      <form
        class="relative"
        @submit.prevent="submitSearch"
      >
        <input
          ref="input"
          v-model="inputValue"
          type="text"
          name="search"
          :placeholder="$t('General.Search.Placeholder')"
          class="w-full border border-gray-60 p-5 text-lg text-main"
          required
          @input="getSuggestions"
        />

        <div class="absolute right-0 top-0 flex aspect-square h-full p-2">
          <button
            type="submit"
            tabindex="-1"
            :class="[
              'flex h-full w-full cursor-pointer items-center justify-center bg-primary',
              'transition-color duration-300 hover:bg-tertiary'
            ]"
          >
            <BaseIcon
              class="text-icon text-white"
              name="search"
            />
          </button>
        </div>
      </form>

      <ul
        v-if="suggestions.length"
        class="border-x border-b border-gray-60"
      >
        <li
          v-for="(suggestion, index) in suggestions"
          :id="`suggestion-${index}`"
          :key="`${suggestion.pageName}-${index}`"
          tabindex="0"
          class="cursor-pointer px-4 py-3 outline-none odd:bg-gray-20 hover:bg-primary hover:text-white focus:bg-primary focus:text-white"
          @click="pickSuggestion"
        >
          <p>{{ suggestion.pageName }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
