<script setup lang="ts">
import type { AddressObject } from 'types/footer';

const props = defineProps<{
  address?: AddressObject;
}>();

const { content } = props.address || {};
</script>

<template>
  <address class="mb-2 flex flex-col text-base not-italic text-white">
    <p
      v-if="content?.companyName"
      class="font-bold"
    >
      {{ content.companyName }}
      <small v-if="content?.area">{{ content.area }}</small>
    </p>

    <p v-if="content?.address">
      {{ content.address }}
    </p>

    <p v-if="content?.zip">
      {{ content.zip }}
      <template v-if="content?.city">{{ content.city }}</template>
    </p>

    <BaseLink
      v-if="content?.phoneNumber"
      :to="`tel:${content.phoneNumber.replaceAll(' ', '')}`"
      :has-underline="true"
      class="mt-2 text-white"
    >
      {{ content.phoneNumber }}
    </BaseLink>

    <BaseLink
      v-if="content?.email"
      :to="`mailto:${content.email}`"
      :has-underline="true"
      class="text-white"
    >
      {{ content.email }}
    </BaseLink>
  </address>
</template>
