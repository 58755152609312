<script setup lang="ts">
import { useScroll } from '@vueuse/core';
import { extractNumberFromCSSVariable } from 'utils';

const layout = useLayout();
const root = useRoot();
const navigation = useNavigation();
const searchBar = ref<HTMLElement | null>(null);
const searchButton = ref<HTMLElement>();

const showMetaMenu = ref<boolean>(true);

const megaMenuEnabled = computed(() => {
  return root.settings?.content?.megaMenuEnabled ?? false;
});

const metaMenuEnabled = computed(() => {
  return root.settings?.content?.metaMenuEnabled ?? false;
});

const computedHeaderHeight = computed<number>(() => {
  return extractNumberFromCSSVariable('--header-height');
});

const computedMetaMenuTransform = computed(() => {
  if (!layout.isDesktop || !metaMenuEnabled.value) {
    return '';
  }

  return showMetaMenu.value ? 'translate-y-0' : '-translate-y-meta-menu';
});

onMounted(() => {
  const DOM = useDOM();
  const { y } = useScroll(DOM?.window);

  watch(
    () => y.value,
    (newValue) => {
      const isScrolledDown = newValue > computedHeaderHeight.value;
      showMetaMenu.value = !isScrolledDown;
    }
  );
});

onClickOutside(
  searchBar,
  () => {
    navigation.toggleSearchVisibility();
  },
  { ignore: [searchButton] }
);
</script>

<template>
  <header
    class="fixed left-0 top-0 z-50 w-full transform transition-transform duration-400 ease-smooth"
    :class="[computedMetaMenuTransform]"
  >
    <MetaMenuContainer v-if="layout.isDesktop && metaMenuEnabled" />

    <div class="relative z-50 flex h-header w-full items-center border-b border-b-gray-30 bg-white">
      <div
        v-if="layout.isDesktop"
        class="container flex h-full lg:justify-between"
      >
        <TheLogo
          v-if="root.settings?.content?.siteLogo"
          :logo="root.settings?.content?.siteLogo.url"
          loading="eager"
          class="flex items-center"
        />
        <div class="flex h-full items-center gap-4">
          <div class="navigation-wrapper">
            <DesktopMainNavigation :mega-menu-enabled="megaMenuEnabled" />
          </div>

          <button
            ref="searchButton"
            type="button"
            class="flex cursor-pointer transition-opacity duration-300 hover:opacity-50"
            @click="navigation.toggleSearchVisibility()"
          >
            <BaseIcon
              class="text-icon"
              name="search"
            />
          </button>
        </div>
      </div>

      <div
        v-else
        class="container flex items-center justify-between"
      >
        <TheLogo
          v-if="root.settings?.content?.siteLogo"
          :logo="root.settings?.content?.siteLogo.url"
          class="flex items-center"
          loading="eager"
        />

        <div class="flex flex-row gap-6">
          <button
            :aria-label="navigation.searchVisible ? $t('General.CloseSearch') : $t('General.OpenSearch')"
            type="button"
            class="flex cursor-pointer"
            @click="navigation.toggleSearchVisibility()"
          >
            <BaseIcon
              class="text-[26px]"
              name="search"
            />
          </button>

          <MobileMegaNavigation v-if="megaMenuEnabled" />
          <MobileMainNavigation v-else />
        </div>
      </div>
    </div>

    <Transition name="search">
      <SearchBar
        v-if="root.settings?.content?.searchResultsPage?.url && navigation.searchVisible"
        ref="searchBar"
        :search-page="root.settings.content.searchResultsPage"
      />
    </Transition>
  </header>
</template>
