<script lang="ts" setup>
import type { NavigationItem } from 'types/index';
import { useNavigation } from 'stores/navigation';

type Props = {
  level?: number;
  children?: NavigationItem[];
  identifier?: string;
  parentName?: string;
  maxLevel?: number;
};

const props = withDefaults(defineProps<Props>(), {
  level: 0,
  ctaEnabled: false,
  children: undefined,
  identifier: '',
  parentName: 'root',
  maxLevel: 3
});

const navigation = useNavigation();
const shownIdentifier = shallowRef<string>('');

const hasChildren = (item: NavigationItem): boolean => {
  if (item.children) {
    return item.children.length > 0;
  } else {
    return false;
  }
};

// Note you must manually add the classes in the scoped style at the bottom if you increase the levels!
const currentActiveLevelClass = computed(() => {
  if (props.level !== 0) {
    return '';
  }
  return `translate-x-${navigation.megaMenuActiveSubLevel}00`;
});

function handleShowActive(identifier: string) {
  shownIdentifier.value = identifier;
}
const atMaxLevel = computed(() => {
  return props.level === props.maxLevel;
});
</script>

<template>
  <ul
    :class="[props.level == 0 ? 'relative' : 'w-full', currentActiveLevelClass]"
    class="container flex flex-col py-4 font-bold transition-transform duration-300 ease-in-out"
  >
    <li
      v-if="parentName !== 'root'"
      class="flex items-center border-b border-secondary"
    >
      <button
        role="button"
        class="router-link-active flex h-full w-full px-2 py-4"
        @click="
          handleShowActive(props.identifier);
          navigation.updateActiveSubLevel(navigation.megaMenuActiveSubLevel - 1);
        "
      >
        <BaseIcon
          class="relative top-px mr-4 flex h-5 w-5 items-center justify-center text-icon"
          name="chevron-right"
        />
        {{ parentName }}
      </button>
    </li>
    <li
      v-for="(item, i) in children"
      :key="i + item.menuTitle"
      :class="`sublevel${props.level}`"
      class="flex items-center border-b border-secondary"
    >
      <BaseLink
        :to="item.linkUrl"
        :title="item.menuTitle"
        class="h-full w-full px-2 py-4"
      >
        {{ item.menuTitle }}
      </BaseLink>
      <BaseIcon
        v-if="hasChildren(item) && !atMaxLevel"
        class="relative flex h-14 w-14 items-center justify-center text-icon"
        name="chevron-right"
        @click="
          handleShowActive(i + item.menuTitle);
          navigation.updateActiveSubLevel(navigation.megaMenuActiveSubLevel + 1, item.menuTitle);
        "
      />
      <MobileMegaMenuLevels
        v-if="hasChildren(item) && !atMaxLevel"
        :children="item.children"
        :level="props.level + 1"
        :parent-name="item.menuTitle"
        :identifier="i + item.menuTitle"
        :class="shownIdentifier === i + item.menuTitle ? 'visible' : 'invisible'"
        class="absolute left-0 top-0 translate-x-full"
      />
    </li>
  </ul>
</template>

<style lang="postcss" scoped>
.router-link-exact-active {
  color: var(--color-primary);
}

.router-link-active {
  color: var(--color-primary);
  background-color: var(--color-white);
}

.mega-menu {
  top: var(--header-height);
}

/*Extend tailwind, only for this component as it will take too long to extend dynamically.*/
.translate-x-100 {
  transform: translateX(-100%);
}

.translate-x-200 {
  transform: translateX(-200%);
}

.translate-x-300 {
  transform: translateX(-300%);
}
</style>
