<script setup lang="ts">
const root = useRoot();
const navigation = useNavigation();

const megaMenuCTAEnabled = root.settings.content.megaMenuCTAEnabled;
const megaMenuCTAHeadingText = root.settings.content.megaMenuCTAHeadingText;
const megaMenuCTA = root.settings.content.megaMenuCTA;

useHead({
  bodyAttrs: {
    class: navigation.noScrollBodyClass
  }
});
</script>

<template>
  <div>
    <div id="main-menu">
      <div class="navigation-wrapper flex items-center gap-4">
        <PartialsHamburgerMenuButton
          class="ml-auto lg:hidden"
          @click="navigation.toggleMenuVisibility()"
        />
      </div>
    </div>
    <Transition name="menu">
      <nav
        v-show="navigation.menuVisible"
        class="main-navigation absolute left-0 top-full z-50 flex h-full w-full flex-col overflow-y-auto overflow-x-hidden bg-white"
      >
        <MobileMegaMenuLevels :children="navigation.navigationItems" />
        <div class="container mt-auto">
          <div
            v-if="megaMenuCTAEnabled && megaMenuCTAHeadingText.trim() !== ''"
            class="mb-4"
          >
            <p class="mb-2 text-primary">
              {{ megaMenuCTAHeadingText }}
            </p>

            <BaseButton
              v-if="megaMenuCTA"
              :to="megaMenuCTA.url"
            >
              {{ megaMenuCTA.name }}
            </BaseButton>
          </div>
          <LanguageSelector />
          <MetaMenuLinks />
        </div>
      </nav>
    </Transition>
  </div>
</template>

<style lang="postcss" scoped>
.main-navigation {
  height: calc(100vh - var(--header-height));
  height: calc(100svh - var(--header-height));
}

.menu-enter-active {
  transition: all 0.3s ease-out;
}

.menu-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.menu-enter-from,
.menu-leave-to {
  transform: translateX(100%);
}
</style>
