<script setup lang="ts">
import type { NavigationItem } from 'types';

const navigation = useNavigation();

const hasChildren = (item: NavigationItem): boolean => {
  if (item.children) {
    return item.children.length > 0;
  } else {
    return false;
  }
};

useHead({
  bodyAttrs: {
    class: navigation.noScrollBodyClass
  }
});

const openItem = shallowRef<number | null>(null);

const toggleItem = (index: number) => {
  if (openItem.value === index) {
    openItem.value = null;
  } else {
    openItem.value = index;
  }
};
</script>

<template>
  <div>
    <div id="main-menu">
      <div class="navigation-wrapper flex items-center gap-4">
        <PartialsHamburgerMenuButton
          class="ml-auto lg:hidden"
          @click="navigation.toggleMenuVisibility()"
        />
      </div>
    </div>
    <Transition name="menu">
      <nav
        v-show="navigation.menuVisible"
        class="main-navigation absolute left-0 top-full z-50 flex w-full flex-col bg-white px-8 py-4"
      >
        <ul class="flex flex-col">
          <li
            v-for="(item, index) in navigation.navigationItems"
            :key="item.menuTitle"
            :class="hasChildren(item) ? 'group/item' : ''"
            class="border-b border-gray-40 text-lg last-of-type:border-b-0"
          >
            <BaseLink
              v-if="!hasChildren(item)"
              :to="item.linkUrl"
              :title="item.menuTitle"
              class="block py-4"
            >
              {{ item.menuTitle }}
            </BaseLink>
            <MobileNavigationSublinks
              v-if="hasChildren(item)"
              :index="index"
              :nav-item="item"
              :open="openItem === index"
              @click="toggleItem(index)"
            />
          </li>
        </ul>
        <div class="mt-auto">
          <LanguageSelector />
          <MetaMenuLinks />
        </div>
      </nav>
    </Transition>
  </div>
</template>

<style lang="postcss" scoped>
.main-navigation {
  height: calc(100vh - var(--header-height));
}

.menu-enter-active {
  transition: all 0.3s ease-out;
}

.menu-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.menu-enter-from,
.menu-leave-to {
  transform: translateX(100%);
}

.router-link-exact-active {
  color: var(--color-primary);
}
</style>
