<script lang="ts" setup>
import type { NavigationItem } from 'types/index';

type Props = {
  level?: number;
  ctaEnabled?: boolean;
  children?: NavigationItem[];
  index?: number;
  parent: NavigationItem;
  maxLevel?: number;
};

const navigation = useNavigation();

const props = withDefaults(defineProps<Props>(), {
  level: 0,
  ctaEnabled: false,
  children: undefined,
  index: 0,
  maxLevel: 2
});

const hasChildren = (item: NavigationItem): boolean => {
  if (item.children) {
    return item.children.length > 0;
  } else {
    return false;
  }
};

const atMaxLevel = computed(() => {
  return props.level === props.maxLevel;
});

function setShownIndexes(index: number) {
  if (props.level === 0) {
    navigation.megaMenuActiveIndexes = [index];
  } else {
    navigation.megaMenuActiveIndexes[props.level] = index;
  }
}
</script>

<template>
  <ul
    :class="[props.level == 0 ? 'relative h-[inherit]' : 'h-full w-full', props.ctaEnabled ? 'w-1/4' : 'w-1/3']"
    class="flex flex-col pb-4 pl-4 pt-4"
    :data-level="props.level"
  >
    <li class="flex justify-between border-b border-secondary">
      <BaseLink
        :to="props.parent.linkUrl"
        :title="props.parent.menuTitle"
        class="hover:transition-color flex h-full flex-1 items-center px-2 py-4 text-left font-semibold text-black hover:text-primary"
      >
        {{ $t('General.ViewAll') + ' - ' + parent.menuTitle }}
      </BaseLink>
    </li>
    <li
      v-for="(item, i) in children"
      :key="i"
      class="flex justify-between border-b border-secondary"
    >
      <button
        v-if="hasChildren(item) && !atMaxLevel"
        type="button"
        :title="item.menuTitle"
        class="font-sans font-semi-bold hover:transition-color flex h-full flex-1 items-center px-2 py-4 text-left hover:text-primary hover:ease-in"
        :class="{
          'text-primary': navigation.megaMenuActiveIndexes[props.level] === i
        }"
        @click="setShownIndexes(i)"
      >
        {{ item.menuTitle }}
        <BaseIcon
          v-if="hasChildren(item) && !atMaxLevel"
          :class="{
            'text-primary': navigation.megaMenuActiveIndexes[props.level] === i
          }"
          class="top relative top-px ml-auto flex h-5 w-5 items-center justify-center text-icon transition-transform duration-200"
          name="chevron-right"
        />
      </button>

      <BaseLink
        v-else
        :to="item.linkUrl"
        :title="item.menuTitle"
        class="hover:transition-color flex h-full flex-1 items-center px-2 py-4 font-bold hover:text-primary hover:ease-in"
        :class="{
          'text-primary': navigation.megaMenuActiveIndexes[props.level] === i
        }"
        @click="setShownIndexes(i)"
      >
        {{ item.menuTitle }}
        <BaseIcon
          v-if="hasChildren(item) && !atMaxLevel"
          :class="{
            'text-primary': navigation.megaMenuActiveIndexes[props.level] === i
          }"
          class="top relative top-px ml-auto flex h-5 w-5 -rotate-90 items-center justify-center text-icon transition-transform duration-200"
          name="chevron-down"
        />
      </BaseLink>

      <MegaMenuLevels
        v-if="hasChildren(item) && !atMaxLevel"
        :parent="item"
        :children="item.children"
        :level="props.level + 1"
        :max-level="maxLevel"
        :class="{
          invisible: !(navigation.megaMenuActiveIndexes[props.level] === i)
        }"
        class="absolute top-0 translate-x-[calc(100%-1rem)]"
      />
    </li>
  </ul>
</template>

<style lang="postcss" scoped>
.sub-links .router-link-exact-active {
  color: var(--color-tertiary);
  background-color: var(--color-white);
}

.router-link-exact-active {
  color: var(--color-primary);
}

.mega-menu {
  top: var(--header-height);
}
</style>
