<script setup lang="ts">
const root = useRoot();
const layout = useLayout();
</script>

<template>
  <div :class="[layout.isDesktop && root.settings?.content?.metaMenuEnabled ? 'pt-header-meta-menu' : 'pt-header']">
    <TheHeader />
    <slot></slot>
    <TheFooter />
  </div>
</template>
