<script setup lang="ts">
import type { Link } from 'types/index';

type Props = Link & {
  hasUnderline?: boolean;
};

const props = defineProps<Props>();

const linkIsExternal = computed(() => {
  return props.linkType === 'External';
});
</script>

<template>
  <BaseLink
    :to="linkIsExternal ? props.url : handleRelativeUrl(props.url)"
    :target="linkIsExternal ? '_blank' : props.target"
    :rel="linkIsExternal ? 'noopener noreferrer' : ''"
    :has-underline="props.hasUnderline"
  >
    {{ props.name }}
  </BaseLink>
</template>
