<script setup lang="ts">
import { useNavigation } from 'stores/navigation';
import type { NavigationItem } from 'types/index';

const root = useRoot();
const navigation = useNavigation();
const megaMenuNav = shallowRef<HTMLElement | null>(null);

const props = defineProps<{
  megaMenuEnabled: boolean;
}>();

const megaMenuCTAEnabled = root.settings.content.megaMenuCTAEnabled;
const megaMenuCTAHeadingText = root.settings.content.megaMenuCTAHeadingText;
const megaMenuCTA = root.settings.content.megaMenuCTA;

const hasChildren = (item: NavigationItem): boolean => {
  if (item.children) {
    return item.children.length > 0;
  } else {
    return false;
  }
};

const setShownMenuIndex = (index: number) => {
  if (navigation.megaMenuShownIndex === index) {
    navigation.megaMenuShownIndex = -1;
  } else {
    navigation.megaMenuShownIndex = index;
  }
};

onClickOutside(megaMenuNav, () => setShownMenuIndex(-1));

useHead({
  bodyAttrs: {
    class: navigation.noScrollBodyClass
  }
});
</script>

<template>
  <div
    id="main-menu"
    class="h-full"
  >
    <div class="navigation-wrapper hidden h-full md:flex">
      <nav
        ref="megaMenuNav"
        class="main-navigation h-full"
      >
        <ul
          v-if="navigation.navigationItems.length"
          class="flex h-full select-none items-center"
        >
          <li
            v-for="(item, index) in navigation.navigationItems"
            :key="item.menuTitle"
            :class="[{ relative: !megaMenuEnabled }]"
          >
            <BaseLink
              v-if="!props.megaMenuEnabled || !hasChildren(item)"
              :to="item.linkUrl"
              :title="item.menuTitle"
              :has-underline="true"
              :thick-underline="true"
              :class="[
                'transition-color group/item relative -mb-6 flex h-full items-center px-2.5 pb-9 pt-3 text-lg duration-300',
                'hover:text-primary'
              ]"
              @click.prevent.self="setShownMenuIndex(index)"
            >
              {{ item.menuTitle }}
              <BaseIcon
                v-if="hasChildren(item)"
                class="relative top-px ml-1 flex h-5 w-5 -rotate-0 items-center justify-center text-icon transition-transform duration-200 group-hover/item:rotate-180"
                name="chevron-down"
              />
            </BaseLink>
            <button
              v-if="props.megaMenuEnabled && hasChildren(item)"
              type="button"
              :title="`${$t('General.ClickToView')} ${item.menuTitle}`"
              :class="[
                {
                  'border-primary text-primary': navigation.megaMenuShownIndex === index
                },
                {
                  'border-transparent': navigation.megaMenuShownIndex !== index
                }
              ]"
              class="transition-color font-sans relative z-[51] -mb-6 box-border flex h-full items-center border-b-2 px-2.5 pb-9 pt-3 text-lg font-normal duration-300"
              @click.prevent.self="setShownMenuIndex(index)"
            >
              {{ item.menuTitle }}
              <BaseIcon
                v-if="hasChildren(item)"
                :class="{ 'rotate-0': navigation.megaMenuShownIndex !== index }"
                class="pointer-events-none relative top-px ml-1 flex h-5 w-5 -rotate-180 items-center justify-center text-icon transition-transform duration-200"
                name="chevron-down"
              />
            </button>
            <template v-if="false">
              <!-- WARNING: at the current height, the MegaMenu will start scrolling if the TOP LEVEL has more than 10 links
                however if any of the sublevels have more than 10 (and top level is not at a higher amount), we will go outside the MegaMenu's height,
                this is due to the "absolute" approach for sub levels.
              -->
            </template>

            <nav
              v-if="props.megaMenuEnabled && hasChildren(item)"
              class="mega-menu bg-white"
              :class="[
                'text-lg font-bold',
                'max-h-screen min-h-[60vh] w-full overflow-y-auto',
                'absolute left-1/2 -translate-x-1/2',
                { invisible: navigation.megaMenuShownIndex !== index }
              ]"
            >
              <div class="container relative flex flex-1 flex-row py-16 2xl:py-6">
                <button
                  type="button"
                  class="absolute right-8 top-8"
                  @click="setShownMenuIndex(-1)"
                >
                  <BaseIcon
                    class="relative flex h-12 w-12 items-center justify-center"
                    name="close"
                  />
                </button>

                <article
                  v-if="megaMenuCTAEnabled"
                  class="flex w-1/4 flex-col pb-8 pr-8 pt-8"
                >
                  <h3 class="text-4xl mb-4 font-bold leading-tight">
                    {{ megaMenuCTAHeadingText }}
                  </h3>
                  <BaseButton
                    v-if="megaMenuCTA"
                    :to="megaMenuCTA.url"
                    :target="megaMenuCTA.target"
                    class="no-active"
                  >
                    {{ megaMenuCTA.name }}
                  </BaseButton>
                </article>

                <DesktopMegaMenuLevels
                  :parent="item"
                  :cta-enabled="megaMenuCTAEnabled"
                  :children="item.children"
                  :level="0"
                />
              </div>
            </nav>

            <ul
              v-if="!props.megaMenuEnabled && hasChildren(item)"
              class="sub-links absolute left-1/2 top-12 hidden w-max -translate-x-1/2 flex-col border border-primary bg-tertiary bg-white px-2 py-4 text-primary shadow-inner group-focus-within/item:flex group-hover/item:flex"
            >
              <li
                v-for="(child, i) in item.children"
                :key="i"
                class=""
              >
                <BaseLink
                  :to="child.linkUrl"
                  :title="child.menuTitle"
                  class="transition-color my-2 flex h-full items-center px-6 py-2 duration-200 hover:bg-tertiary"
                >
                  {{ child.menuTitle }}
                </BaseLink>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.sub-links .router-link-exact-active {
  color: var(--color-tertiary);
  background-color: var(--color-white);
}

.router-link-exact-active:not(.no-active) {
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.mega-menu {
  top: var(--header-height);
  transition: all 0.2s ease-out;
  transform-origin: top;
  --tw-translate-y: 0;
  opacity: 1;

  &.invisible {
    pointer-events: none;
    --tw-translate-y: -20px;
    opacity: 0;
  }
}
</style>
