<script setup lang="ts">
const layout = useLayout();
const root = useRoot();
</script>

<template>
  <footer class="relative mt-auto w-full bg-secondary">
    <div class="container py-12 lg:py-16">
      <div
        v-if="root.settings?.content?.footerLogo"
        class="mb-8 lg:mb-16"
      >
        <TheLogo
          v-if="root.settings?.content?.footerLogo"
          :logo="root.settings?.content?.footerLogo.url"
          loading="lazy"
          class="block w-fit"
        />
      </div>

      <div
        v-if="layout.isDesktop"
        class="grid gap-6 lg:grid-cols-12"
      >
        <div class="col-span-5">
          <AddressInformation
            v-for="(address, addressIndex) in root.settings?.content.address"
            :key="`address-${addressIndex}`"
            :address="address"
            class="mb-20"
          />
        </div>

        <div class="col-span-1 w-full text-white lg:col-span-7">
          <div
            v-if="root.settings?.content.quickLinks"
            class="gap-6 lg:flex lg:w-full lg:justify-end"
          >
            <div
              v-for="quickLinkColumn in root.settings?.content.quickLinks"
              :key="quickLinkColumn.content.headline"
              class="lg:w-1/3"
            >
              <p class="mb-2 font-bold">
                {{ quickLinkColumn.content.headline }}
              </p>
              <nav id="footer-quick-links">
                <ul class="grid gap-2">
                  <li
                    v-for="quickLink in quickLinkColumn.content.links"
                    :key="quickLink.name"
                    class="hyphens-auto"
                  >
                    <UmbracoLink
                      v-bind="quickLink"
                      :has-underline="true"
                      class="text-base"
                    />
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <BaseAccordionWrapper
          v-slot="slotProps"
          class="text-white"
        >
          <BaseAccordionItem
            :key="0"
            :accordion-index="0"
            :is-open="slotProps.isActiveAccordion(0)"
            @update:is-open="slotProps.setActiveAccordion"
          >
            <template #title>
              <h3>Kontakt afdeling</h3>
            </template>
            <AddressInformation
              v-for="(address, index) in root.settings?.content.address"
              :key="index"
              :address="address"
              class="mb-5"
            />
          </BaseAccordionItem>

          <BaseAccordionItem
            v-for="(quickLinkColumn, index) in root.settings?.content.quickLinks"
            :key="index + 1"
            :accordion-index="index + 1"
            :is-open="slotProps.isActiveAccordion(index + 1)"
            @update:is-open="slotProps.setActiveAccordion"
          >
            <template #title>
              <h3>{{ quickLinkColumn.content.headline }}</h3>
            </template>
            <ul class="grid gap-2">
              <li
                v-for="(quickLink, linkIndex) in quickLinkColumn.content.links"
                :key="`link-${linkIndex}`"
              >
                <UmbracoLink
                  v-bind="quickLink"
                  :has-underline="true"
                  class="text-base"
                />
              </li>
            </ul>
          </BaseAccordionItem>
        </BaseAccordionWrapper>
      </div>
    </div>

    <FooterBottomMenu />
  </footer>
</template>
