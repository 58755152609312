<script setup lang="ts">
const root = useRoot();
</script>

<template>
  <ul
    v-if="root.settings.content.metaMenuCTALinks?.length"
    class="flex h-full gap-6"
  >
    <li
      v-for="ctaLink in root.settings.content.metaMenuCTALinks"
      :key="ctaLink.name"
      class="flex flex-1"
    >
      <BaseLink
        class="box-border flex h-full w-full justify-center whitespace-nowrap hover:text-primary lg:items-center"
        :to="ctaLink.url"
        :target="ctaLink.target"
        :has-underline="true"
      >
        {{ ctaLink.name }}
      </BaseLink>
    </li>
  </ul>
</template>

<style lang="postcss" scoped>
.router-link-exact-active {
  color: var(--color-primary);
}
</style>
