<script setup lang="ts"></script>

<template>
  <div class="relative z-[60] flex select-none items-center bg-gray-10 lg:h-meta-menu">
    <div class="container flex h-full">
      <LanguageSelector />
      <MetaMenuLinks class="ml-auto" />
    </div>
  </div>
</template>
