<script setup lang="ts">
const root = useRoot();

const footerIcons = reactive([
  {
    icon: 'facebook',
    link: root.settings?.content.facebook,
    title: root.clientName + ' Facebook'
  },
  {
    icon: 'twitter',
    link: root.settings?.content.twitter,
    title: root.clientName + ' Twitter'
  },
  {
    icon: 'youtube',
    link: root.settings?.content.youtube,
    title: root.clientName + ' YouTube'
  },
  {
    icon: 'instagram',
    link: root.settings?.content.instagram,
    title: root.clientName + ' Instagram'
  },
  {
    icon: 'linkedin',
    link: root.settings?.content.linkedin,
    title: root.clientName + ' LinkedIn'
  }
]);
</script>

<template>
  <div class="py-10">
    <nav
      id="footer-bottom-menu"
      class="container flex flex-col justify-between gap-10 lg:flex-row"
    >
      <ul
        class="flex flex-col justify-between gap-4 text-center text-base text-white"
        :class="['lg:flex-row lg:text-left']"
      >
        <li v-if="root.settings?.content.cvr">
          <p class="text-left">{{ $t('General.Settings.Cvr') }} {{ root.settings?.content.cvr }}</p>
        </li>

        <li v-if="root.settings?.content.email">
          <BaseLink
            :to="'mailto:' + root.settings?.content.email"
            :has-underline="true"
          >
            {{ root.settings?.content.email }}
          </BaseLink>
        </li>

        <li v-if="root.settings?.content.cookieLink">
          <UmbracoLink
            v-bind="root.settings?.content.cookieLink"
            :has-underline="true"
          />
        </li>

        <li v-if="root.settings?.content.privacyPolicy">
          <UmbracoLink
            v-bind="root.settings?.content.privacyPolicy"
            :has-underline="true"
          />
        </li>
      </ul>

      <FooterSoMe
        v-if="footerIcons?.length"
        :icons="footerIcons"
      />
    </nav>
  </div>
</template>
